import { List, ListItemText, ListItemButton, Avatar, Box, Typography } from '@mui/material';
import { Contact } from '../../@types/messenger';
import { stringAvatar } from '../../utils/avatar';
import { StyledBadge } from '../../style/styledBadge';
import { formatConversationDate } from '../../utils/date-format-handler';

interface ContactListProps {
    contactList: Contact[];
    selectedContactID?: Number;
    onSelectContact: (cont: Contact) => void;
}

export default function ContactList({ contactList, selectedContactID, onSelectContact }: ContactListProps) {
    return (
        <Box
            sx={{
                overflowY: 'scroll',
                flexGrow: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 211px)',
            }}>
            {contactList.map(contact => (
                <List key={contact.ID} sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: 0, paddingBottom: 0, borderRight: 'none' }}>
                    <ListItemButton
                        onClick={() => onSelectContact(contact)}
                        sx={{
                            bgcolor: selectedContactID === contact.ID ? '#e0f7fa' : 'background.paper',
                            '&:hover': {
                                bgcolor: '#e0f7fa',
                            },
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderRight: 1,
                            borderColor: 'divider',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <StyledBadge
                                overlap='circular'
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant='dot'
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: contact.IsOnline ? '#44b700' : '#ff0000',
                                        color: contact.IsOnline ? '#44b700' : '#ff0000',
                                    },
                                }}>
                                <Avatar {...stringAvatar(contact.UserName)} />
                            </StyledBadge>
                            <ListItemText
                                primary={contact.UserName}
                                secondary={
                                    <Typography
                                        sx={{
                                            maxWidth: '15rem',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                        }}
                                        variant='body2'
                                        color='text.primary'>
                                        {contact.LastMessageID === 0 ? (
                                            'Nincs üzenet'
                                        ) : contact.NAdminUnread > 0 ? (
                                            <strong>{contact.LastMessageText}</strong>
                                        ) : (
                                            contact.LastMessageText
                                        )}
                                    </Typography>
                                }
                                sx={{
                                    marginLeft: 1,
                                }}
                            />
                        </Box>
                        <Box>
                            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                                {formatConversationDate(contact.LastMessageAt)}
                            </Typography>
                        </Box>
                    </ListItemButton>
                </List>
            ))}
        </Box>
    );
}
