import { AppBar, Toolbar, Typography, Avatar, Grid, IconButton } from '@mui/material';
import { stringAvatar } from '../../utils/avatar';
import { StyledBadge } from '../../style/styledBadge';
import { green, red } from '@mui/material/colors';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Contact } from '../../@types/messenger';

interface ConversationHeaderProps {
    userName: string;
    userID: string;
    isOnline: boolean;
    setSelectedContact: (contact?: Contact) => void;
}

export default function ConversationHeader({ userName, userID, isOnline, setSelectedContact }: ConversationHeaderProps) {
    return (
        <AppBar position='static' color='default' elevation={3} sx={{ borderRadius: 2, py: 1, paddingLeft: 0 }}>
            <Toolbar variant='dense'>
                <Grid
                    item
                    sx={{
                        display: {
                            xs: 'block',
                            md: 'none',
                        },
                    }}>
                    <IconButton size='small' edge='start' color='inherit' aria-label='menu' onClick={() => setSelectedContact(undefined)}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                </Grid>
                <Grid container alignItems='center' sx={{ flexGrow: 1 }}>
                    <StyledBadge
                        overlap='circular'
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant='dot'
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: isOnline ? green[500] : red[500],
                                color: isOnline ? green[500] : red[500],
                            },
                        }}>
                        <Avatar {...stringAvatar(userName)} />
                    </StyledBadge>

                    <Grid item ml={1} mt={1}>
                        <Typography key={userID} variant='h6' color='inherit' sx={{ lineHeight: 1 }}>
                            {userName}
                        </Typography>
                        <Typography variant='caption' color='textSecondary' sx={{ lineHeight: 1 }}>
                            {isOnline ? 'Elérhető' : 'Nem elérhető'}
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
