import { Grid, Divider, Box, CircularProgress } from '@mui/material';
import ConversationHeader from './ConversationHeader';
import ConversationFooter from './ConversationFooter';
import { MessagePresentation } from './MessagePresentation';
import { Contact, ChatMessage, MessageResponse, MessageFilterPresentation, MessageContentType } from '../../@types/messenger';
import { formatConversationLineDate } from '../../utils/date-format-handler';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { useEffect, useRef, useState } from 'react';
import React from 'react';

const MESSENGER_API_ENDPOINT = process.env.REACT_APP_MESSENGER_API_ENDPOINT;
const MESSAGE_CONTENT_TYPE_PERMISSIONS = process.env.REACT_APP_MESSENGER_MESSAGE_CONTENT_TYPE_PERMISSIONS || '{}';
const messagePermission = JSON.parse(MESSAGE_CONTENT_TYPE_PERMISSIONS);

interface ConversationProps {
    contact: Contact;
    websocketMessages: ChatMessage[];
    setSelectedContact: (contact?: Contact) => void;
    selectedFilter?: MessageContentType | null;
}

export default function Conversation({ contact, websocketMessages, setSelectedContact, selectedFilter }: ConversationProps) {
    const limit = 25;
    const { user } = useAuth();
    const messageContainer = useRef<HTMLDivElement>(null);
    const freshMessageRef = useRef<HTMLDivElement>(null);
    const oldestMessageRef = useRef<HTMLDivElement>(null);
    const { showError, showResponseError } = useProvideSnackBar();
    const [messageList, setMessageList] = useState<ChatMessage[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMoreMessage, setHasMoreMessage] = useState<boolean>(true);
    const [readAtIds, setReadAtIds] = useState<number[]>([]);

    const fetchMessageSend = async (text: string) => {
        if (!text.trim()) {
            return;
        }
        try {
            const response = await fetch(`${MESSENGER_API_ENDPOINT}/api/admin/messages/send`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    RecipientID: contact.UserID,
                    RecipeientName: contact.UserName,
                    Text: text,
                    SentAt: new Date(),
                }),
            });
            if (!response.ok) {
                showResponseError(response);
                return;
            }

            const body: ChatMessage = await response.json();
            setMessageList([body, ...messageList]);
            freshMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
        } catch (error: any) {
            showError(`Hiba történt az üzenet elküldése közben ${error.message}`);
        }
    };

    const fetchMessageList = async () => {
        if (!hasMoreMessage && messageList.length > 0) {
            return;
        }
        setLoading(true);
        try {
            const response = await fetch(
                MESSENGER_API_ENDPOINT +
                    '/api/messages/list?' +
                    new URLSearchParams({
                        limit: limit.toString(),
                        offset: messageList.length.toString(),
                        user_id: contact.UserID.toString(),
                        filter: selectedFilter || '',
                    }).toString(),
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.accessToken}`,
                    },
                }
            );

            if (response.ok) {
                const messageData: MessageResponse = await response.json();
                setHasMoreMessage(messageData.messages.length >= limit);
                setMessageList([...messageList, ...messageData.messages]);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt az üzenetek lekérdezése közben: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const fetchMessageSetRead = async () => {
        const ids = messageList
            .filter(
                m =>
                    m.SenderIsDriver &&
                    !m.ReadAt?.Valid &&
                    user?.roles?.some(r => messagePermission[m.MessageContentType] !== undefined && messagePermission[m.MessageContentType].includes(r)) &&
                    !readAtIds.includes(m.ID)
            )
            .map(m => m.ID);

        if (ids.length === 0) {
            return;
        }
        try {
            const response = await fetch(`${MESSENGER_API_ENDPOINT}/api/admin/messages/set_read`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    IDs: ids,
                }),
            });

            if (!response.ok) {
                showResponseError(response);
                return;
            }
            setReadAtIds([...readAtIds, ...ids]);
        } catch (error: any) {
            showError(`Hiba történt: ${error.message}`);
        }
    };

    useEffect(() => {
        setMessageList([]);
        setReadAtIds([]);
        setHasMoreMessage(true);
    }, [contact, selectedFilter]);

    useEffect(() => {
        if (messageList.length === 0) {
            fetchMessageList();
        }
        fetchMessageSetRead();
    }, [messageList]);

    useEffect(() => {
        if (websocketMessages.length > 0 && messageList.length > 0) {
            setMessageList([...websocketMessages.filter(m => m.ID > messageList[0].ID).reverse(), ...messageList]);
        }
    }, [websocketMessages]);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (Math.abs(scrollTop) + clientHeight >= scrollHeight - 100 && !loading && hasMoreMessage) {
            fetchMessageList();
        }
    };

    return (
        <Grid
            item
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '70%',
            }}>
            <ConversationHeader userID={contact.UserID} userName={contact.UserName} isOnline={contact.IsOnline} setSelectedContact={setSelectedContact} />
            <Box
                sx={{
                    overflowY: 'scroll',
                    flexGrow: 1,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    pl: 1,
                    pr: 2,
                    py: 1,
                }}
                ref={messageContainer}
                onScroll={handleScroll}>
                {messageList.map((message, index) => {
                    return (
                        <React.Fragment key={message.ID}>
                            {index > 0 && message.SentAt.slice(0, 10) !== messageList[index - 1].SentAt.slice(0, 10) && (
                                <Box>
                                    <Divider>{formatConversationLineDate(messageList[index - 1].SentAt)}</Divider>
                                </Box>
                            )}
                            <MessagePresentation
                                messageDetails={message}
                                messageReadAt={readAtIds.includes(message.ID) || message.ReadAt?.Valid}
                                ref={index === 0 ? freshMessageRef : oldestMessageRef}
                            />
                        </React.Fragment>
                    );
                })}
                {loading && (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}

                {!hasMoreMessage && (
                    <Box>
                        <Divider>
                            {selectedFilter
                                ? 'Nincs több megjeleníthető üzenet ebben a kategóriában: ' + MessageFilterPresentation[selectedFilter]
                                : 'Nincs több üzenete'}
                        </Divider>
                    </Box>
                )}
            </Box>

            <ConversationFooter onMessageSend={(message: string) => fetchMessageSend(message)} />
        </Grid>
    );
}
