import { Grid, Divider, Box, CircularProgress, Button } from '@mui/material';
import ConversationHeader from './ConversationHeader';
import ConversationFooter from './ConversationFooter';
import { MessagePresentation } from './MessagePresentation';
import { Contact, ChatMessage, MessageResponse, MessageDirection } from '../../@types/messenger';
import { formatConversationLineDate } from '../../utils/date-format-handler';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { useEffect, useRef, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';

const MESSENGER_API_ENDPOINT = process.env.REACT_APP_MESSENGER_API_ENDPOINT;

interface SearchMessageListProps {
    contact: Contact;
    message: ChatMessage;
    setSelectedContact: (contact?: Contact) => void;
}

export default function SearchMessageList({ contact, message, setSelectedContact }: SearchMessageListProps) {
    const limit = 10;
    const { user } = useAuth();
    const messageContainer = useRef<HTMLDivElement>(null);
    const freshMessageRef = useRef<HTMLDivElement>(null);
    const oldestMessageRef = useRef<HTMLDivElement>(null);
    const { showError, showResponseError } = useProvideSnackBar();
    const [messageList, setMessageList] = useState<ChatMessage[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [nextDivider, setNextDivider] = useState<boolean>(true);
    const [previousDivider, setPreviousDivider] = useState<boolean>(true);

    const fetchMessageSend = async (text: string) => {
        if (!text.trim()) {
            return;
        }
        try {
            const response = await fetch(`${MESSENGER_API_ENDPOINT}/api/admin/messages/send`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    RecipientID: contact.UserID,
                    RecipeientName: contact.UserName,
                    Text: text,
                    SentAt: new Date(),
                }),
            });
            if (!response.ok) {
                showResponseError(response);
                return;
            }

            const body: ChatMessage = await response.json();
            setMessageList([body, ...messageList]);
            freshMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
        } catch (error: any) {
            showError(`Hiba történt az üzenet elküldése közben ${error.message}`);
        }
    };

    const fetchMessageLoad = async (direction: MessageDirection) => {
        setLoading(true);
        try {
            const params = new URLSearchParams({
                user_id: contact.UserID.toString(),
                message_id: direction === MessageDirection.NEXT ? messageList[0].ID.toString() : messageList[messageList.length - 1].ID.toString(),
                direction: direction.toString(),
                limit: limit.toString(),
            });

            const response = await fetch(`${MESSENGER_API_ENDPOINT}/api/admin/messages/load?${params.toString()}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });

            if (response.ok) {
                const messageData: MessageResponse = await response.json();
                if (direction === MessageDirection.NEXT) {
                    setMessageList([...messageData.messages, ...messageList]);
                    setNextDivider(messageData.messages.length === limit);
                } else {
                    setMessageList([...messageList, ...messageData.messages]);
                    setPreviousDivider(messageData.messages.length === limit);
                }
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba a keresés során: ${error.message}`);
        }
        setLoading(false);
    };

    useEffect(() => {
        setMessageList([message]);
        setNextDivider(true);
        setPreviousDivider(true);
    }, [message]);

    return (
        <Grid
            item
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '70%',
            }}>
            <ConversationHeader userID={contact.UserID} userName={contact.UserName} isOnline={contact.IsOnline} setSelectedContact={setSelectedContact} />

            <Box
                sx={{
                    overflowY: 'scroll',
                    flexGrow: 1,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    pl: 1,
                    pr: 2,
                    py: 1,
                }}
                ref={messageContainer}>
                {nextDivider && (
                    <Box>
                        <Divider>
                            {
                                <Button
                                    variant='contained'
                                    size='small'
                                    endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <KeyboardArrowDownIcon />}
                                    sx={{ borderRadius: 25 }}
                                    onClick={() => fetchMessageLoad(MessageDirection.NEXT)}>
                                    Későbbi üzenetek betöltése
                                </Button>
                            }
                        </Divider>
                    </Box>
                )}
                {messageList.map((message, index) => (
                    <React.Fragment key={message.ID}>
                        {index > 0 && message.SentAt.slice(0, 10) !== messageList[index - 1].SentAt.slice(0, 10) && (
                            <Box>
                                <Divider>{formatConversationLineDate(messageList[index - 1].SentAt)}</Divider>
                            </Box>
                        )}
                        <MessagePresentation
                            messageDetails={message}
                            messageReadAt={message.ReadAt?.Valid}
                            ref={index === 0 ? freshMessageRef : oldestMessageRef}
                        />
                    </React.Fragment>
                ))}
                {previousDivider && (
                    <Box>
                        <Divider>
                            {
                                <Button
                                    variant='contained'
                                    size='small'
                                    endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <KeyboardArrowUpIcon />}
                                    sx={{ borderRadius: 25 }}
                                    onClick={() => fetchMessageLoad(MessageDirection.PREVIOUS)}>
                                    Korábbi üzenetek betöltése
                                </Button>
                            }
                        </Divider>
                    </Box>
                )}
            </Box>

            <ConversationFooter onMessageSend={(message: string) => fetchMessageSend(message)} />
        </Grid>
    );
}
