import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Props } from '../@types';
import { AuthContextType, Role, User } from '../@types/auth';
import { decodePayload } from '../utils/decodePayload';

const KEYCLOAK_ENDPOINT = process.env.REACT_APP_OAUTH2_PROVIDER_REFRESH_ENDPOINT;
const AUTH_COOKIE_DOMAIN = process.env.REACT_APP_AUTH_COOKIE_DOMAIN;

const DefaultUser: User = {
    accessToken: '',
    email: '',
    exp: 0,
    hasRole(role: Role): boolean {
        return false;
    },
    isAccessExpired(): boolean {
        return true;
    },
    keycloakUserId: '',
    name: '',
    refreshToken: '',
    roles: [],
    username: '',
    workplace: '',
};

const AuthContext = createContext<AuthContextType>({
    user: DefaultUser,
    isAuthenticated: false,
    isAuthenticationInitialized: false,
    signIn: (authToken: string, refreshToken: string) => console.log('signIn'),
    signOut: () => console.log('signOut'),
    setAuthToken: (token: string) => console.log('setToken'),
    setRefreshToken: (token: string) => console.log('setToken'),
});

const useProvideAuth = () => {
    const [user, setUser] = useState<User>(DefaultUser);
    const [isAuthenticationInitialized, setIsAuthenticationInitialized] = useState<boolean>(false);

    const getAccessToken = () => {
        return localStorage.getItem('access-token');
    };

    const getRefreshToken = () => {
        return localStorage.getItem('refresh-token');
    };

    const signOut = async () => {
        setUser(DefaultUser);
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        window.location.assign('/');
    };

    const setAccessToken = (token: string) => {
        localStorage.setItem('access-token', token);
        const updatedUser = Object.assign({}, user);
        updatedUser.accessToken = token;
        setUser(updatedUser);
    };

    const setRefreshToken = (token: string) => {
        localStorage.setItem('refresh-token', token);
        const updatedUser = Object.assign({}, user);
        updatedUser.refreshToken = token;
        setUser(updatedUser);
    };

    const setCookie = (cname: string, cvalue: string, expiredAt: number) => {
        const expirationDay = new Date();
        expirationDay.setTime(expiredAt * 1000);
        document.cookie = `${cname}=${cvalue};expires=${expirationDay.toUTCString()};domain=${AUTH_COOKIE_DOMAIN};path=/`;
    };

    const signIn = async (accessToken: string, refreshToken: string) => {
        try {
            const payload = decodePayload(accessToken);

            if (!payload) {
                console.error('no payload');
                return;
            }

            const { exp, preferred_username, family_name, given_name, email, realm_access, workplace, sub } = payload;
            setAccessToken(accessToken);
            setRefreshToken(refreshToken);
            const resolvedUser = {
                keycloakUserId: sub,
                username: preferred_username,
                name: `${family_name} ${given_name}`,
                email,
                roles: realm_access.roles,
                accessToken,
                refreshToken,
                exp,
                workplace,
                hasRole: (role: string) => realm_access.roles.some(r => r === role),
                isAccessExpired: () => new Date().getTime() < exp,
            };
            setCookie('authorization', accessToken, exp);
            setUser(resolvedUser);
        } catch (e) {
            console.error(e);
        }
    };

    const refreshTheToken = async () => {
        if (!KEYCLOAK_ENDPOINT) {
            return;
        }
        const response = await fetch(KEYCLOAK_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                client_id: 'buszrent-crm',
                refresh_token: (await getRefreshToken()) || '',
                grant_type: 'refres_token',
            }),
        });
        const body = await response.json();
        setAccessToken(body.access_token);
        setRefreshToken(body.refresh_token);
    };

    useEffect(() => {
        const init = () => {
            const accessToken = getAccessToken();
            const refreshToken = getRefreshToken() || '';
            if (accessToken) {
                signIn(accessToken, refreshToken);
            }
            setIsAuthenticationInitialized(true);
        };
        init();
    }, []);

    return {
        user,
        isAuthenticated: user !== undefined && !!user.accessToken,
        isAuthenticationInitialized,
        signIn,
        signOut,
        setAuthToken: setAccessToken,
        setRefreshToken,
    };
};

const useAuth = () => {
    return useContext(AuthContext);
};

const ProvideAuth = ({ children }: Props) => {
    const auth = useProvideAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const accessToken = searchParams.get('t');
    const refreshToken = searchParams.get('r') || '';

    useEffect(() => {
        const initAuth = () => {
            if (accessToken && auth.signIn && typeof auth.signIn === 'function') {
                auth.setAuthToken(accessToken);
                auth.setRefreshToken(refreshToken);
                auth.signIn(accessToken, refreshToken);
                setSearchParams();
                return;
            }

            const storedAccessToken = localStorage.getItem('access-token') || '';
            const storedRefreshToken = localStorage.getItem('refresh-token') || '';
            auth.signIn(storedAccessToken, storedRefreshToken);
        };
        initAuth();
    }, []);

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export { useAuth, useProvideAuth, ProvideAuth };
