export enum MessageContentType {
    MECHANICAL = 'MECHANICAL',
    PERSONAL = 'PERSONAL',
    TASK = 'TASK',
}
export const MessageFilterPresentation = {
    [MessageContentType.MECHANICAL]: 'Műszaki üzenetek',
    [MessageContentType.PERSONAL]: 'Személyes üzenetek',
    [MessageContentType.TASK]: 'Feladathoz kapcsolódó üzenetek',
};

export enum MessageDirection {
    PREVIOUS = 'previous',
    NEXT = 'next',
}

export type Contact = {
    ID: number;
    UserName: string;
    UserID: string;
    NAdminUnread: number;
    NUserUnread: number;
    IsOnline: boolean;
    LastMessageID: number;
    LastMessageText: string;
    LastMessageAt: string;
    UpdatedAt: string;
};

export type MessageResponse = {
    messages: ChatMessage[];
    count: number;
};

export type ContactResponse = {
    contacts: Contact[];
};

export type ChatMessage = {
    ID: number;
    SenderID: string;
    SenderName: string;
    SenderUserName: string;
    RecipientID: string;
    RecipientName: string;
    RecipientUserName: string;
    Text: string;
    MessageContentType: MessageContentType;
    ReadAt?: {
        Time: string;
        Valid: boolean;
    };
    ReadBy: string;
    ReadByName: string;
    SentAt: string;
    CreatedAt: string;
    LicensePlate: string;
    Lat: number;
    Lon: number;
    SenderIsDriver: boolean;
    TaskSyncedAt: Date | null;
};
