import { Grid } from '@mui/material';
import AsideFilter from './AsideFilter';
import SearchBar from './SearchBar';
import Conversation from './Conversation';
import { SecuredLayout } from '../Layout/SecuredLayout';
import ContactList from './ContactList';
import { useEffect, useState } from 'react';
import { Contact, ChatMessage, ContactResponse, MessageContentType } from '../../@types/messenger';
import { useAuth, useProvideSnackBar } from '../../hooks';
import useWebSocket from 'react-use-websocket';
import SearchResults from './SearchResults';
import SearchMessageList from './SearchMessageList';
import { useUnreadMessagesParams } from '../../hooks/useUnreadMessageContext';
import { faviconChange } from '../../utils/faviconChange';

const MESSENGER_API_ENDPOINT = process.env.REACT_APP_MESSENGER_API_ENDPOINT;
const MESSENGER_WEBSOCKET_ENDPOINT = process.env.REACT_APP_MESSENGER_WEBSOCKET_ENDPOINT;

export default function MessengerForm() {
    const { user, isAuthenticationInitialized } = useAuth();
    const [selectedContact, setSelectedContact] = useState<Contact>();
    const [searchSelectedMessage, setSearchSelectedMessage] = useState<ChatMessage | null>(null);
    const { showError, showResponseError } = useProvideSnackBar();
    const [contactList, setContactList] = useState<Contact[]>([]);
    const [websocketMessages, setWebsocketMessages] = useState<ChatMessage[]>([]);
    const [selectedFilter, setSelectedFilter] = useState<MessageContentType | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const { unReadMessagesCount, setUnreadMessagesCount } = useUnreadMessagesParams();

    const { sendJsonMessage } = useWebSocket(`${MESSENGER_WEBSOCKET_ENDPOINT}/api/admin/ws`, {
        onMessage: (event: any) => {
            let jsonData = JSON.parse(event.data);

            if ('messages' in jsonData) {
                setWebsocketMessages(jsonData['messages']);
            }

            if ('contacts' in jsonData) {
                const socketContacts: Contact[] = jsonData['contacts'];
                setContactList(socketContacts);
                setUnreadMessagesCount(socketContacts.reduce((acc, contact) => acc + contact.NAdminUnread, 0));
            }
        },
    });

    const fetchGetContactList = async () => {
        if (!user?.accessToken) {
            return;
        }

        try {
            const response = await fetch(
                MESSENGER_API_ENDPOINT +
                    '/api/admin/contacts?' +
                    new URLSearchParams({
                        filter: selectedFilter || '',
                    }).toString(),
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.accessToken}`,
                    },
                }
            );

            if (response.ok) {
                const contactData: ContactResponse = await response.json();
                setContactList(contactData.contacts);

                if (contactData.contacts.length > 0) {
                    setSelectedContact(contactData.contacts[0]);
                    setUnreadMessagesCount(contactData.contacts.reduce((acc, contact) => acc + contact.NAdminUnread, 0));
                    sendJsonMessage({
                        type: 'setPartnerUser',
                        userID: contactData.contacts[0].UserID,
                        lastMessageID: contactData.contacts[0].LastMessageID,
                    });
                }
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt a kapcsolatok lekérdezése közben: ${error.message}`);
        }
    };

    useEffect(() => {
        if (isAuthenticationInitialized) {
            fetchGetContactList();
        }
    }, [isAuthenticationInitialized, selectedFilter]);

    useEffect(() => {
        let isOriginalTitle = true;

        const updateTitle = () => {
            if (unReadMessagesCount > 0 && isOriginalTitle) {
                faviconChange('mail-box.ico');
                document.title = `(${unReadMessagesCount}) olvasatlan üzenete van`;
            } else {
                faviconChange('favicon.ico');
                document.title = 'BuszRent | BusMan üzenetküldő';
            }
            isOriginalTitle = !isOriginalTitle;
        };

        updateTitle();

        const interval = setInterval(updateTitle, 6000);

        return () => clearInterval(interval);
    }, [unReadMessagesCount]);

    return (
        <SecuredLayout>
            <Grid container sx={{ height: 'calc(100vh - 70px)', bgcolor: 'background.paper', boxShadow: 1 }}>
                <Grid
                    item
                    sx={{
                        borderRight: 1,
                        borderColor: 'divider',
                        width: {
                            xs: '100%',
                            md: '23%',
                        },
                        display: {
                            xs: selectedContact ? 'none' : 'block',
                            md: 'block',
                        },
                    }}>
                    <Grid
                        item
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                        }}>
                        <AsideFilter selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                        }}>
                        <SearchBar setSearchText={setSearchText} />
                    </Grid>
                    {searchText.trim() === '' ? (
                        <Grid item>
                            <ContactList
                                contactList={contactList}
                                selectedContactID={selectedContact?.ID}
                                onSelectContact={(contact: Contact) => {
                                    setSelectedContact(contact);
                                    sendJsonMessage({
                                        type: 'setPartnerUser',
                                        userID: contact.UserID,
                                        lastMessageID: contact.LastMessageID,
                                    });
                                }}
                            />
                        </Grid>
                    ) : (
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                            }}>
                            <SearchResults
                                contactList={contactList}
                                searchText={searchText}
                                selectedMessageID={searchSelectedMessage?.ID}
                                onSelect={(contact: Contact, message: ChatMessage) => {
                                    setSelectedContact(contact);
                                    setSearchSelectedMessage(message);
                                    sendJsonMessage({
                                        type: 'setPartnerUser',
                                        userID: contact.UserID,
                                        lastMessageID: contact.LastMessageID,
                                    });
                                }}
                            />
                        </Grid>
                    )}
                </Grid>

                {selectedContact &&
                    (searchText.trim() === '' ? (
                        <Conversation
                            contact={selectedContact}
                            setSelectedContact={setSelectedContact}
                            websocketMessages={websocketMessages}
                            selectedFilter={selectedFilter}
                        />
                    ) : (
                        searchSelectedMessage && (
                            <SearchMessageList contact={selectedContact} setSelectedContact={setSelectedContact} message={searchSelectedMessage} />
                        )
                    ))}
            </Grid>
        </SecuredLayout>
    );
}
