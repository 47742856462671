import React from 'react';
import { Route, Routes } from 'react-router';
import { NotFoundPage } from './Common';
import { BrowserRouter } from 'react-router-dom';
import { Box, createTheme, styled, ThemeProvider } from '@mui/material';
import { ProvideAuth } from '../hooks';
import { ProvideSnackBar } from '../hooks/useSnackBar';
import MessengerForm from './Messenger/MessengerForm';
import { UnreadMessagesProvider } from '../hooks/useUnreadMessageContext';

const StyledApp = styled(Box)`
    background-image: url(/images/brb_logo_vertical_white.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40%;
    min-height: 100%;
    height: 100%;
`;

const theme = createTheme({
    palette: {
        primary: {
            main: '#42C4AB',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <StyledApp>
                <BrowserRouter>
                    <ProvideAuth>
                        <UnreadMessagesProvider>
                            <ProvideSnackBar>
                                <Routes>
                                    <Route path='/' element={<MessengerForm />} />
                                    <Route path='/*' element={<NotFoundPage />} />
                                </Routes>
                            </ProvideSnackBar>
                        </UnreadMessagesProvider>
                    </ProvideAuth>
                </BrowserRouter>
            </StyledApp>
        </ThemeProvider>
    );
}

export default App;
