import { Box, Typography, Popover } from '@mui/material';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { ChatMessage, MessageContentType } from '../../@types/messenger';
import L from 'leaflet';

interface DriverDetailsCardProps {
    open: boolean;
    onClose: () => void;
    messageDetails: ChatMessage;
    anchorMessage?: HTMLButtonElement | null;
}

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default function DriverDetailsCard({ open, onClose, messageDetails, anchorMessage }: DriverDetailsCardProps) {
    return (
        <Popover
            open={open}
            anchorEl={anchorMessage}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={() => onClose()}>
            <Box sx={{ p: 1 }}>
                <Typography sx={{ fontSize: '0.8rem' }}>
                    <strong>Elküldve: </strong>
                    {messageDetails?.SentAt !== undefined &&
                        new Date(messageDetails?.SentAt).toLocaleString('hu-HU', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                        })}
                </Typography>
                <Typography sx={{ fontSize: '0.8rem' }}>
                    <strong>Elmentve: </strong>
                    {messageDetails?.CreatedAt !== undefined &&
                        new Date(messageDetails?.CreatedAt).toLocaleString('hu-HU', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                        })}
                </Typography>
                <Typography sx={{ fontSize: '0.8rem' }}>
                    <strong>Elolvasva: </strong>
                    {messageDetails?.ReadAt?.Valid &&
                        new Date(messageDetails?.ReadAt?.Time).toLocaleString('hu-HU', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                        })}
                </Typography>
                <Typography sx={{ fontSize: '0.8rem' }}>
                    <strong>Elolvasta: </strong>
                    {messageDetails?.ReadAt?.Valid && (messageDetails.ReadByName || '')}
                </Typography>
                {messageDetails.SenderIsDriver && (
                    <>
                        <Typography sx={{ fontSize: '0.8rem' }}>
                            <strong>Busz rendszám: </strong>
                            {messageDetails?.LicensePlate}
                        </Typography>
                        {messageDetails.MessageContentType === MessageContentType.MECHANICAL && (
                            <Typography sx={{ fontSize: '0.8rem' }}>
                                <strong>Szinkronizálás időpontja: </strong>
                                {messageDetails?.TaskSyncedAt &&
                                    new Date(messageDetails?.TaskSyncedAt).toLocaleString('hu-HU', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric',
                                    })}
                            </Typography>
                        )}
                        <Box sx={{ height: '150px', width: '300px' }}>
                            <MapContainer
                                center={[messageDetails?.Lat || 0, messageDetails?.Lon || 0]}
                                zoom={16}
                                zoomControl={false}
                                scrollWheelZoom={true}
                                style={{ height: '100%' }}>
                                <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                                <Marker position={[messageDetails?.Lat || 0, messageDetails?.Lon || 0]}></Marker>
                            </MapContainer>
                        </Box>
                    </>
                )}
            </Box>
        </Popover>
    );
}
