import { Button, CardActions, CardContent, CircularProgress, Modal, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import SyncIcon from '@mui/icons-material/Sync';
import { useAuth, useProvideSnackBar } from '../../utils';
import { useState } from 'react';
import { ConfirmCard } from '../Layout/styles';
import { ChatMessage } from '../../@types/messenger';

const MESSENGER_API_ENDPOINT = process.env.REACT_APP_MESSENGER_API_ENDPOINT;
const GARAGE_API_ENDPOINT = process.env.REACT_APP_GARAGE_API_ENDPOINT;

interface SyncMessageConfirmModalProps {
    open: boolean;
    onClose: () => void;
    messageDetails: ChatMessage;
}

export default function SyncMessageConfirmModal({ open, onClose, messageDetails }: SyncMessageConfirmModalProps) {
    const { user } = useAuth();
    const { showError, showSuccess, showResponseError } = useProvideSnackBar();
    const [loading, setLoading] = useState(false);

    const fetchSyncMessage = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await fetch(`${GARAGE_API_ENDPOINT}/api/sync`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    ID: messageDetails.ID,
                    LicensePlate: messageDetails.LicensePlate,
                    SenderName: messageDetails.SenderName,
                    Text: messageDetails.Text,
                }),
            });
            if (response.ok) {
                showSuccess(`Sikeres szinkronizálás`);
                await fetchMessage();
            } else {
                if (response.status === 404) {
                    showError(`${response.status} | Ismeretlen busz`);
                }
            }
        } catch (error: any) {
            showResponseError(error.message);
        } finally {
            setLoading(false);
            onClose();
        }
    };

    const fetchMessage = async () => {
        try {
            const response = await fetch(`${MESSENGER_API_ENDPOINT}/api/admin/messages/` + messageDetails.ID, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                const body: ChatMessage = await response.json();
                messageDetails.TaskSyncedAt = body.TaskSyncedAt;
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError('Hiba történt az üzenet lekérdezése közben');
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography paragraph>
                        A <strong>SZINKRONIZÁLÁS</strong> gombra kattintva az üzenet szinkronizálhatja a kapcsolódó busz üzenetei közzé.
                    </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={() => onClose()}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        Mégsem
                    </Button>
                    <Button
                        variant='contained'
                        size={'small'}
                        sx={{ borderRadius: 50, marginRight: 0.25 }}
                        onClick={() => fetchSyncMessage()}
                        endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <SyncIcon />}>
                        Szinkronizálás
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
