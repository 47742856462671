export function stringAvatar(name: string) {
    if (!name) {
        return {
            sx: {
                bgcolor: 'grey.500',
                marginRight: 1,
            },
            children: '?',
        };
    }
    const splittedName = name.split(' ');
    let chName = splittedName[0][0].toUpperCase();
    if (splittedName.length > 1) {
        chName += splittedName[1][0].toUpperCase();
    }
    return {
        sx: {
            bgcolor: 'grey.500',
            marginRight: 1,
        },
        children: chName,
    };
}
