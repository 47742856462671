import {Grid, IconButton, TextField, Tooltip} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {useState} from 'react';

interface ConversationFooterProps {
    onMessageSend: (message: string) => void;
}

export default function ConversationFooter({onMessageSend}: ConversationFooterProps) {
    const [message, setMessage] = useState<string>('');

    return (
        <Grid container sx={{padding: 2, alignItems: 'center'}}>
            <TextField
                fullWidth
                size={'small'}
                variant='outlined'
                placeholder='Írj üzenetet...'
                value={message}
                onChange={e => setMessage(e.target.value)}
                multiline
                maxRows={4}
                onKeyDown={e => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        onMessageSend(message);
                        setMessage('');
                    }
                }}
                InputProps={{
                    endAdornment:
                        (message.length > 0 && <Tooltip title='Küldés'>
                            <IconButton
                                color={'primary'}
                                onClick={() => {
                                    onMessageSend(message);
                                    setMessage('');
                                }}
                                sx={{marginRight: 0, py: 0}}>
                                <SendIcon/>
                            </IconButton>
                        </Tooltip>)
                }}
                sx={{
                    borderRadius: 50,
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 50,
                    },
                }}
            />
        </Grid>
    );
}
