import { Drawer, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import MessageIcon from '@mui/icons-material/Message';
import { useState } from 'react';
import { MessageContentType, MessageFilterPresentation } from '../../@types/messenger';

interface AsideFilterProps {
    selectedFilter: MessageContentType | null;
    setSelectedFilter: (filter: MessageContentType | null) => void;
}

export default function AsideFilter({ selectedFilter, setSelectedFilter }: AsideFilterProps) {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const handleFilterSelect = (filter: MessageContentType | null) => {
        setSelectedFilter(filter);
        setDrawerOpen(false);
    };

    return (
        <>
            <Grid container justifyContent='space-between' alignItems='center' sx={{ padding: 1.5 }}>
                <Typography variant='h6' pl={1}>
                    {selectedFilter === null ? 'Összes üzenet' : MessageFilterPresentation[selectedFilter]}
                </Typography>
                <Grid item>
                    <Tooltip title='Szűrés'>
                        <IconButton onClick={() => setDrawerOpen(true)} color='primary'>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <Drawer
                anchor='left'
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                sx={{
                    '.MuiDrawer-paper': {
                        height: '23vh',
                        marginTop: '15vh',
                        borderRadius: '0 0 10px 10px',
                    },
                }}>
                <List sx={{ marginTop: 2 }}>
                    <ListItemButton onClick={() => handleFilterSelect(null)}>
                        <ListItemIcon>
                            <MessageIcon />
                        </ListItemIcon>
                        <ListItemText primary='Összes üzenetek' />
                    </ListItemButton>
                    <ListItemButton onClick={() => handleFilterSelect(MessageContentType.MECHANICAL)}>
                        <ListItemIcon>
                            <BusAlertIcon />
                        </ListItemIcon>
                        <ListItemText primary='Műszaki üzenetek' />
                    </ListItemButton>
                    <ListItemButton onClick={() => handleFilterSelect(MessageContentType.PERSONAL)}>
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary='Személyes üzenetek' />
                    </ListItemButton>
                    <ListItemButton onClick={() => handleFilterSelect(MessageContentType.TASK)}>
                        <ListItemIcon>
                            <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary='Feladathoz kapcsolódó üzenetek' />
                    </ListItemButton>
                </List>
            </Drawer>
        </>
    );
}
