import { Box, Card, styled } from '@mui/material';

export const PageHeader = styled(Box)`
    background-image: url('/images/header-background.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: 100%;
`;

export const Greeter = styled(Box)`
    color: white;
    display: flex;
    align-items: center;
`;

export const ConfirmCard = styled(Card)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 400px;
    background-color: white;
    box-shadow: 2px 2px;
    padding-bottom: 14px;

    @media (max-width: 900px) {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(0%, 0%);
        width: 100%;
    }
`;

export const DecorLine = styled(Box)(
    ({ theme }) => `
    position: absolute;
    width: 100%; 
    text-align: center;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        width: calc(50% - 20px); 
        height: 1px;
        background-color: ${theme.palette.divider};
    }
    &:before {
        left: 0;
        margin-right: 10px; 
    }
    &:after {
        right: 0;
        margin-left: 10px; 
    }`
);
