const leftPad = (number: number) => {
    return number < 10 ? '0' + number : number.toString();
};

const formatDateToYyyyMMdd = (date: Date | null) => {
    if (!date) return '';
    const month = leftPad(date.getMonth() + 1);
    const dayOfMonth = leftPad(date.getDate());
    return `${date.getFullYear()}-${month}-${dayOfMonth}`;
};

const formatDateToYyyyMMddhhmmss = (date: Date | null) => {
    if (!date) return '';
    const month = leftPad(date.getMonth() + 1);
    const dayOfMonth = leftPad(date.getDate());
    return `${date.getFullYear()}-${month}-${dayOfMonth} 00:00:00`;
};


const formatConversationDate = (dateString: string) => {
    const conversationDate = new Date(dateString);
    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

    const isToday = conversationDate.toDateString() === now.toDateString();
    const isWithinAWeek = conversationDate > oneWeekAgo;

    if (isToday) {
        return conversationDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    if (isWithinAWeek) {
        return conversationDate.toLocaleDateString('hu-HU', { weekday: 'short' });
    }
    return conversationDate.toLocaleDateString('hu-HU');
};

const formatConversationLineDate = (dateString: string) => {
    const messageDate = new Date(dateString);
    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

    const isToday = messageDate.toDateString() === now.toDateString();
    const isWithinAWeek = messageDate > oneWeekAgo && messageDate.toDateString() !== now.toDateString();

    if (isToday) {
        return 'ma';
    } else if (isWithinAWeek) {
        return messageDate.toLocaleDateString('hu-HU', { weekday: 'long' });
    } else {
        return messageDate.toLocaleDateString('hu-HU', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            weekday: 'long',
        });
    }
};

const formatMessageDate = (dateString: string) => {
    const messageDate = new Date(dateString);
    return messageDate.toLocaleTimeString('hu-HU', { hour: '2-digit', minute: '2-digit' });
};

const isMoreThanAMinuteBetween = (date1: number, date2: number) => {
    return Math.abs(date2 - date1) / 60000 > 1;
};

export { formatDateToYyyyMMdd, formatDateToYyyyMMddhhmmss, formatConversationDate, formatConversationLineDate, formatMessageDate, isMoreThanAMinuteBetween };
