import { ListItemText, ListItemButton, Avatar, Box, Typography, Paper, MenuItem, CircularProgress } from '@mui/material';
import { ChatMessage, Contact, MessageResponse } from '../../@types/messenger';
import { stringAvatar } from '../../utils/avatar';
import { formatConversationDate } from '../../utils/date-format-handler';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth, useProvideSnackBar } from '../../hooks';

interface SearchResultsProps {
    contactList: Contact[];
    searchText: string;
    selectedMessageID?: Number;
    onSelect: (cont: Contact, message: ChatMessage) => void;
}
const MESSENGER_API_ENDPOINT = process.env.REACT_APP_MESSENGER_API_ENDPOINT;

export default function SearchResults({ contactList, selectedMessageID, onSelect, searchText }: SearchResultsProps) {
    const limit = 10;
    const { user } = useAuth();
    const messageContainer = useRef<HTMLDivElement>(null);
    const freshMessageRef = useRef<HTMLDivElement>(null);
    const oldestMessageRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { showError, showResponseError } = useProvideSnackBar();
    const [searchedChatMessages, setSearchedChatMessages] = useState<ChatMessage[]>([]);
    const [hasMoreSearchMessage, setHasMoreSearchMessage] = useState<boolean>(true);
    const [searchCount, setSearchCount] = useState<number>(0);

    const fetchMessageSearch = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                MESSENGER_API_ENDPOINT +
                    '/api/admin/messages/search?' +
                    new URLSearchParams({
                        text: searchText.toString(),
                        limit: limit.toString(),
                        offset: searchedChatMessages.length.toString(),
                    }).toString(),
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.accessToken}`,
                    },
                }
            );
            if (response.ok) {
                const searchData: MessageResponse = await response.json();
                setHasMoreSearchMessage(searchData.messages.length >= limit);
                setSearchedChatMessages([...searchedChatMessages, ...searchData.messages]);
                setSearchCount(searchData.count);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba a keresés során: ${error.message}`);
        }
        setLoading(false);
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (Math.abs(scrollTop) + clientHeight <= scrollHeight && !loading) {
            fetchMessageSearch();
        }
    };

    useEffect(() => {
        setSearchedChatMessages([]);
    }, [searchText]);

    useEffect(() => {
        if (
            !loading &&
            (hasMoreSearchMessage || searchedChatMessages.length === 0) &&
            messageContainer &&
            messageContainer.current &&
            messageContainer.current.scrollHeight <= messageContainer.current.clientHeight
        ) {
            fetchMessageSearch();
        }
    }, [searchedChatMessages]);

    return (
        <>
            <Paper
                style={{
                    top: '100%',
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    maxWidth: '100%',
                }}>
                <MenuItem sx={{ justifyContent: 'center' }}> {searchCount ? 'Találatok száma: ' + searchCount : 'Nincs találat...'}</MenuItem>
            </Paper>
            <Box
                sx={{
                    overflowY: 'scroll',
                    flexGrow: 1,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 245px)',
                }}
                ref={messageContainer}
                onScroll={handleScroll}>
                {searchedChatMessages.map((message, index) => {
                    return (
                        <React.Fragment key={message.ID}>
                            <Box ref={index === 0 ? freshMessageRef : oldestMessageRef}>
                                <ListItemButton
                                    onClick={() => {
                                        const contact = contactList.find(c => c.UserID === message.SenderID || c.UserID === message.RecipientID);
                                        if (contact) onSelect(contact, message);
                                    }}
                                    sx={{
                                        bgcolor: selectedMessageID === message.ID ? '#e0f7fa' : 'background.paper',
                                        '&:hover': {
                                            bgcolor: '#e0f7fa',
                                        },
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        borderRight: 1,
                                        borderColor: 'divider',
                                    }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar {...stringAvatar(message.SenderIsDriver ? message.SenderName : message.RecipientName)} />
                                        <ListItemText
                                            primary={message.SenderIsDriver ? message.SenderName : message.RecipientName}
                                            secondary={
                                                <Typography
                                                    sx={{
                                                        maxWidth: '15rem',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    variant='body2'
                                                    color='text.primary'>
                                                    {message.Text}
                                                </Typography>
                                            }
                                            sx={{
                                                marginLeft: 1,
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                                            {formatConversationDate(message.SentAt)}
                                        </Typography>
                                    </Box>
                                </ListItemButton>
                            </Box>
                        </React.Fragment>
                    );
                })}
                {loading && (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </>
    );
}
