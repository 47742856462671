import React, { createContext, useContext, useState } from 'react';

const UnreadMessagesContext = createContext({
    unReadMessagesCount: 0,
    setUnreadMessagesCount: (count: number) => {},
});

export const useUnreadMessagesParams = () => useContext(UnreadMessagesContext);

export const UnreadMessagesProvider = ({ children }: { children: React.ReactNode }) => {
    const [unReadMessagesCount, setUnreadMessagesCount] = useState<number>(0);

    return <UnreadMessagesContext.Provider value={{ unReadMessagesCount, setUnreadMessagesCount }}>{children}</UnreadMessagesContext.Provider>;
};
