import { Box, IconButton, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { ChatMessage, MessageContentType } from '../../@types/messenger';
import { formatMessageDate } from '../../utils/date-format-handler';
import InfoIcon from '@mui/icons-material/Info';
import SyncIcon from '@mui/icons-material/Sync';
import { forwardRef, useState } from 'react';
import DriverDetailsCard from './DriverDetailsCard';
import SyncMessageConfirmModal from './SyncMessageConfirmModal';
import CheckIcon from '@mui/icons-material/Check';

interface MessageProps {
    messageDetails: ChatMessage;
    messageReadAt: boolean | undefined;
}

export const MessagePresentation = forwardRef(function ({ messageDetails, messageReadAt }: MessageProps, ref) {
    const theme = useTheme();
    const [driverDetailsCardOpen, setDriverDetailsCardOpen] = useState<boolean>(false);
    const [anchorMessage, setAnchorMessage] = useState<HTMLButtonElement | null>(null);
    const [syncModalOpen, setSyncModalOpen] = useState<boolean>(false);

    function getBackgroundColor() {
        if (!messageDetails.SenderIsDriver) return theme.palette.grey.A200;

        switch (messageDetails.MessageContentType) {
            case MessageContentType.MECHANICAL:
                return theme.palette.warning.light;
            case MessageContentType.TASK:
                return theme.palette.info.light;
            default:
                return theme.palette.secondary.light;
        }
    }

    return (
        <Box
            justifyContent={messageDetails.SenderIsDriver ? 'flex-start' : 'flex-end'}
            sx={{ display: 'flex', flexDirection: 'row' }}
            alignItems={'end'}
            mt={1}
            px={1}
            component={'div'}
            ref={ref}>
            <Paper
                sx={{
                    px: 1,
                    pt: 0.5,
                    backgroundColor: messageDetails.SenderIsDriver ? theme.palette.grey.A100 : theme.palette.common.white,
                    border: '2px solid' + getBackgroundColor(),
                    borderRadius: messageDetails.SenderIsDriver ? '10px 10px 10px 0' : '10px 10px 0 10px',
                    maxWidth: 'calc(100% - 120px)',
                }}>
                {messageReadAt ? (
                    <Typography variant='body1'>{messageDetails.Text}</Typography>
                ) : (
                    <Typography variant='body1'>
                        <strong>{messageDetails.Text}</strong>
                    </Typography>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        mt: -0.5,
                        mr: -1,
                    }}>
                    <Typography variant='caption' color='textSecondary' sx={{ pr: 1.5 }}>
                        {messageDetails.SenderName.split(' ').length > 1
                            ? messageDetails.SenderName.split(' ')[1] + ', ' + formatMessageDate(messageDetails.SentAt)
                            : messageDetails.SenderName + ', ' + formatMessageDate(messageDetails.SentAt)}
                    </Typography>

                    {messageDetails.MessageContentType === MessageContentType.MECHANICAL && !!messageDetails.LicensePlate && (
                        <>
                            {messageDetails.TaskSyncedAt ? (
                                <Tooltip title={'Szinkronizálva'} arrow>
                                    <CheckIcon fontSize={'small'} color='info' sx={{ padding: '4px' }} />
                                </Tooltip>
                            ) : (
                                <IconButton
                                    color='info'
                                    sx={{ padding: '4px 0' }}
                                    onClick={() => {
                                        setSyncModalOpen(true);
                                    }}>
                                    <SyncIcon fontSize={'small'} />
                                </IconButton>
                            )}
                        </>
                    )}

                    <IconButton
                        color='info'
                        sx={{ padding: '4px 4px 4px 0' }}
                        onClick={e => {
                            setDriverDetailsCardOpen(true);
                            setAnchorMessage(e.currentTarget);
                        }}>
                        <InfoIcon fontSize={'small'} />
                    </IconButton>
                </Box>
            </Paper>
            <SyncMessageConfirmModal
                open={syncModalOpen}
                onClose={() => setSyncModalOpen(false)}
                messageDetails={messageDetails}
            />
            <DriverDetailsCard
                open={driverDetailsCardOpen}
                onClose={() => setDriverDetailsCardOpen(false)}
                messageDetails={messageDetails}
                anchorMessage={anchorMessage}
            />
        </Box>
    );
});
