import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface SearchBarProps {
    setSearchText: (text: string) => void;
}

export default function SearchBar({ setSearchText }: SearchBarProps) {
    const [text, setText] = useState<string>('');
    return (
        <Grid container sx={{ padding: 2, position: 'relative' }} justifyContent='center'>
            <TextField
                fullWidth
                variant='outlined'
                size={'small'}
                placeholder='Keresés az üzenetekben'
                value={text}
                onChange={e => setText(e.target.value)}
                onKeyDown={e => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        if (text.trim() === '') {
                            setSearchText('');
                            return;
                        }
                        setSearchText(text);
                    }
                }}
                InputProps={{
                    startAdornment: text ? null : (
                        <InputAdornment position='start'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: text && (
                        <InputAdornment position='end'>
                            <IconButton
                                onClick={() => {
                                    setSearchText('');
                                    setText('');
                                }}>
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '50px',
                    },
                }}
            />
        </Grid>
    );
}
